import { useEffect, useRef } from 'react';
import { SearchRender } from './search-render';
import {
  Dialog,
  UnconstrainedDialogContent
} from "@/components/ui/dialog";
import { SearchInput, SearchButton } from './search-input';
import { useSearchState } from '../use-search-state';
import { TimedProgress } from '@/components/ui/progress';

interface SearchDialogProps {
  isOpen?: boolean;
  onClose?: () => void;
  searchLabel?: string;
  inDDQContext?: boolean;
}

export function SearchDialog({
  isOpen = true,
  onClose,
  searchLabel,
  inDDQContext = false
}: SearchDialogProps) {
  const { addSearchQuery, searchQuery, isLoading } = useSearchState();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
      }, 0);
    }
  }, [isOpen]);

  const handleSearch = () => {
    if (searchQuery.length === 0) return;
    addSearchQuery(searchQuery);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <UnconstrainedDialogContent className='w-[95vw] h-[95vh]'>
        <div className='py-4 border-b flex pl-8 pr-16'>
          <SearchInput ref={inputRef} onSearch={handleSearch} placeholder={searchLabel} />
          <SearchButton onSearch={handleSearch} />
        </div>
        {isLoading ? (
          <div className='flex-grow w-full justify-center flex flex-col items-center px-8'>
            <span className='text-base text-gray-600 mb-4'>
              Searching the Database...
            </span>
            <TimedProgress durationMs={15 * 1000} />
          </div>
        ) : (
          <SearchRender defaultLayout={[15, 20, 65]} inDDQContext={inDDQContext} />
        )}
      </UnconstrainedDialogContent>
    </Dialog>
  );
}
