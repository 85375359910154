'use client';
import * as React from 'react';

import { useDDQState } from '@/components/ddq/use-ddq-state';
import { Separator } from '@/components/ui/separator';

import { SimilarPair } from '@/types';

import { CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import { Textarea } from '@/components/ui/textarea';
import { Button, ButtonLoading } from '@/components/ui/button';
import { useAuthInfo } from '@propelauth/react';
import { Markdown } from '@/components/generic/markdown';
import HighlightCopy from '@/components/generic/highlight-copy';
import { captureEvent, runTimerInMs } from '@/lib/analytics';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

export const GenerationView: React.ComponentType<{
  selectedPair: SimilarPair;
}> = ({ selectedPair }) => {
  const [loading, setLoading] = React.useState(false);

  const {
    state: [ddqState],
    pairObj,
    mutateCurrentEditStateWithGeneration,
    isCurrentAnswerSavingGeneration,
    updateQuestionData,
    setReplacementTextConditionally,
    currentQuestionData,
    addSelectedPairForGeneration,
    removeSelectedPairForGeneration,
    generationInstructions,
    setGenerationInstructions,
    generationResult: generation,
    setGenerationResult: setGeneration
  } = useDDQState();

  const answers = React.useMemo(
    () =>
      selectedPair
        ? selectedPair[1].sort((a, b) => {
            const order = ['high', 'low', false];

            return order.indexOf(a.is_relevant) - order.indexOf(b.is_relevant);
          })
        : [],
    [selectedPair]
  );

  const { accessToken } = useAuthInfo();

  const onGenerate = async () => {
    setLoading(true);
    setGeneration('');

    const timer = runTimerInMs();
    try {
      if (!selectedPair) {
        console.log('no selected pair', selectedPair);
        return;
      }
      if (currentQuestionData?.selectedAnswersForGeneration.length === 0) {
        console.log('no selected answers', currentQuestionData?.selectedAnswersForGeneration);
        return;
      }

      captureEvent('generateAnswerSubmit', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources: currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || ''
      });

      const body = {
        context_pairs: currentQuestionData?.selectedAnswersForGeneration,
        instructions: generationInstructions
      };

      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/pair/${selectedPair[0].id}/draft`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      );

      if (!response.ok) {
        throw new Error('Failed to search responses');
      }

      const results = await response.json();

      setGeneration(results.generation);

      captureEvent('generateAnswerSuccess', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources: currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || '',
        loadTime: timer(),
        result: results.generation
      });
    } catch (e) {
      captureEvent('generateAnswerFailure', {
        id: ddqState.analysisResponse?.id || '',
        friendly_name: ddqState.analysisResponse?.friendly_name,
        approved_date: ddqState.analysisResponse?.approved_date,
        upload_name: ddqState.analysisResponse?.upload_name || '',
        upload_file_type: ddqState.analysisResponse?.upload_mime_type,
        selectedSources:
          currentQuestionData?.selectedAnswersForGeneration || [],
        instructions: generationInstructions || '',
        loadTime: timer(),
        error: String(e)
      });
    } finally {
      setLoading(false);
    }
  };

  const hasGeneration =
    !!generation;
  const isLoading = loading;
  const generatedResult = generation;

  const pair = pairObj ? pairObj[0] : null;

  const onSaveGeneration = React.useCallback(
    async (content: string, sourcePairIds: string[]) => {
      if (!pair) return;

      console.log('save answer generation', pair.id, content, sourcePairIds);

      mutateCurrentEditStateWithGeneration
        .mutateAsync({ id: pair.id, content, sourcePairIds })
        .then((value) => {
          updateQuestionData(pair.id, { content: value.text || '' });
          setReplacementTextConditionally({ id: pair.id, content: value.text });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pair]
  );

  return (
    <div className='flex max-h-full min-h-full flex-col overflow-x-hidden'>
      <div className='flex items-center px-4 py-2'>
        <div className='flex items-center gap-2 my-1'>
          <h1 className='text-xl font-bold'>Draft Answer</h1>
        </div>
      </div>
      <Separator />
      <div className='flex-grow flex flex-col overflow-y-scroll px-2'>
        <div className='px-4 pt-2 text-sm font-semibold'>
          Select answers to generate with:
        </div>
        <div className='pt-1 px-4 text-xs font-light italic'>
          GovernGPT auto-selects in green the answers it finds to be most
          relevant. You can further select or deselect.
        </div>
        {answers.map((answer) => (
          <div
            key={answer.pair.id}
            className={
              `${
                currentQuestionData?.selectedAnswersForGeneration.indexOf(
                  answer.pair.id
                ) !== -1 && 'text-green-700 '
              } hover:bg-gray-100 flex-shrink-0 p-1.5 m-1 border rounded max-w-full overflow-x-hidden overflow-y-hidden text-ellipsis text-sm text-gray-700 h-8 select-none` +
              (loading ? ` cursor-not-allowed` : ` cursor-pointer`)
            }
            title={answer.pair.question}
            onClick={() => {
              if (isLoading) return;

              const index =
                currentQuestionData?.selectedAnswersForGeneration.indexOf(
                  answer.pair.id
                );

              const isSelected = index !== -1;

              captureEvent('generateAnswerSelectSource', {
                id: ddqState.analysisResponse?.id || '',
                friendly_name: ddqState.analysisResponse?.friendly_name,
                approved_date: ddqState.analysisResponse?.approved_date,
                upload_name: ddqState.analysisResponse?.upload_name || '',
                upload_file_type: ddqState.analysisResponse?.upload_mime_type,
                pairId: selectedPair[0].id,
                pairQuestion: selectedPair[0].question,
                sourceDescription: answer.relevancy_description,
                sourceRelevancy: answer.is_relevant,
                sourcePairId: answer.pair.id,
                sourcePairQuestion: answer.pair.question,
                sourcePairApprovalDate: answer.pair.ddq.approved_date,
                sourcePairFriendlyName: answer.pair.ddq.friendly_name,
                selected: !isSelected
              });

              if (index !== -1) removeSelectedPairForGeneration(answer.pair.id);
              else addSelectedPairForGeneration(answer.pair.id);
            }}
          >
            {currentQuestionData?.selectedAnswersForGeneration.indexOf(
              answer.pair.id
            ) !== -1 ? (
              <CheckIcon className='inline' />
            ) : (
              <Cross1Icon className='inline' />
            )}
            &nbsp;&nbsp;
            <span className={'font-semibold'}>
              {answer.relevancy_description}
            </span>
            <Separator
              orientation='vertical'
              className='mx-1 inline-block h-3 bg-gray-300'
            />
            <span className={'text-gray-500'}>
              {answer.pair.ddq.friendly_name}
            </span>
            <Separator
              orientation='vertical'
              className='mx-1 inline-block h-3 bg-gray-300'
            />
            <span className={'text-gray-500'}>
              Approved on {answer.pair.ddq.approved_date.substring(0, 10)}
            </span>
          </div>
        ))}
        <div className='px-4 pt-2 text-sm font-semibold'>
          Add answers you found in search:
        </div>
        <div className='pt-1 px-4 text-xs font-light italic'>
          You can do so by using the search bar next to "Past Answers" and
          clicking "Use with Generation" for answers you want to use here.
        </div>
        {currentQuestionData?.savesFromSearch.map((answer) => (
          <div
            key={answer.pair.id}
            className={
              `${
                currentQuestionData?.selectedAnswersForGeneration.indexOf(
                  answer.pair.id
                ) !== -1 && 'text-green-700 '
              } hover:bg-gray-100 flex-shrink-0 p-1.5 m-1 border rounded max-w-full overflow-x-hidden overflow-y-hidden text-ellipsis text-sm text-gray-700 h-8 select-none` +
              (loading ? ` cursor-not-allowed` : ` cursor-pointer`)
            }
            title={answer.pair.question}
            onClick={() => {
              if (isLoading) return;

              const index =
                currentQuestionData?.selectedAnswersForGeneration.indexOf(
                  answer.pair.id
                );

              const isSelected = index !== -1;

              captureEvent('generateAnswerSelectSource', {
                id: ddqState.analysisResponse?.id || '',
                friendly_name: ddqState.analysisResponse?.friendly_name,
                approved_date: ddqState.analysisResponse?.approved_date,
                upload_name: ddqState.analysisResponse?.upload_name || '',
                upload_file_type: ddqState.analysisResponse?.upload_mime_type,
                pairId: selectedPair[0].id,
                pairQuestion: selectedPair[0].question,
                sourceDescription: answer.relevancy_description,
                sourceRelevancy: answer.is_relevant,
                sourcePairId: answer.pair.id,
                sourcePairQuestion: answer.pair.question,
                sourcePairApprovalDate: answer.pair.ddq.approved_date,
                sourcePairFriendlyName: answer.pair.ddq.friendly_name,
                selected: !isSelected
              });

              if (index !== -1) removeSelectedPairForGeneration(answer.pair.id);
              else addSelectedPairForGeneration(answer.pair.id);
            }}
          >
            {currentQuestionData?.selectedAnswersForGeneration.indexOf(
              answer.pair.id
            ) !== -1 ? (
              <CheckIcon className='inline' />
            ) : (
              <Cross1Icon className='inline' />
            )}
            &nbsp;&nbsp;
            <span className={'font-semibold'}>
              {answer.relevancy_description}
            </span>
            <Separator
              orientation='vertical'
              className='mx-1 inline-block h-3 bg-gray-300'
            />
            <span className={'text-gray-500'}>
              {answer.pair.ddq.friendly_name}
            </span>
            <Separator
              orientation='vertical'
              className='mx-1 inline-block h-3 bg-gray-300'
            />
            <span className={'text-gray-500'}>
              Approved on {answer.pair.ddq.approved_date.substring(0, 10)}
            </span>
          </div>
        ))}
        <div className='px-4 pt-2 text-sm font-semibold'>
          Instructions to generate (optional):
        </div>
        <div className='pt-1 px-4 text-xs font-light italic'>
          If you don't provide any instructions here, the AI will simply try to answer the question.
        </div>
        <div className='flex'>
          <Textarea
            className='m-1'
            value={generationInstructions}
            onChange={(e) => setGenerationInstructions(e.target.value)}
            placeholder='Generation Instructions, for example:
- write this in 2 sentences
- focus on sustainability
- make a table'
            disabled={isLoading}
            rows={4}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                !e.metaKey &&
                !e.shiftKey &&
                !e.altKey &&
                !e.ctrlKey
              ) {
                onGenerate();
              } else if (e.key === 'Enter') {
                setGenerationInstructions(generationInstructions + '\n');
              }
            }}
          />
        </div>
        {!isLoading ? (
          (currentQuestionData?.selectedAnswersForGeneration.length === 0 ? (
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    className='my-1 w-full'
                    onClick={onGenerate}
                    disabled
                  >
                    Generate
                  </Button>
                </TooltipTrigger>
                <TooltipContent side='top'>
                  <p>Select answers to use for generation</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <Button
              className='m-1'
              onClick={onGenerate}
            >
              Generate
            </Button>
          ))
        ) : (
          <ButtonLoading className='m-1'>Generating</ButtonLoading>
        )}
        {hasGeneration && <Separator className='my-2 -mx-2 w-auto' />}
        {hasGeneration && (
          <>
            <div className='flex w-full justify-end gap-2 mb-2'>
              {typeof generatedResult === 'string' &&
                (isCurrentAnswerSavingGeneration ? (
                  <ButtonLoading>Copy to Editor</ButtonLoading>
                ) : (
                  <Button
                    onClick={() =>
                      onSaveGeneration(
                        generatedResult,
                        currentQuestionData?.selectedAnswersForGeneration || []
                      )
                    }
                  >
                    Copy to Editor
                  </Button>
                ))}
            </div>
            <HighlightCopy copyTitle='Copy Response'>
              <Markdown
                className='mx-2 my-1 p-2 border rounded markdown-els bg-gray-100'
                content={generatedResult || ''}
              />
            </HighlightCopy>
          </>
        )}
      </div>
    </div>
  );
};
