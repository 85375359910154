import { cn } from '@/lib/utils';
import { ScrollArea } from '@/components/ui/scroll-area';

import { Markdown } from '@/components/generic/markdown';

import '@/css/list.css';
import { captureEvent } from '@/lib/analytics';
import { useSearchState } from '../use-search-state';

export function SearchList() {
  const { results, selectedResult, setSelectedResult } = useSearchState();

  return (
    <ScrollArea className='flex-grow pt-4 scroll-area-no-table'>
      <div className='flex flex-col gap-2 p-4 pt-0'>
        {results.map((result, i) => (
          <button
            title={result.pair.question}
            key={result.pair.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
              selectedResult?.pair.id === result.pair.id && 'bg-muted border-black'
            )}
            onClick={() => {
              captureEvent('analysisClickQuestionCard', {
                doc_id: result.pair.ddq_id,
                pair_id: result.pair.id,
                index: i
              });

              setSelectedResult(result.pair.id)
            }}
          >
            <div className='flex w-full flex-col gap-1'>
              <div className='italic'>
                {result.pair.ddq.friendly_name} |{' '}
                {result.pair.ddq.approved_date.slice(0, 10)} |{' '}
                {result.relevancy_description} | {result.is_relevant}
              </div>
              <div className='line-clamp-2 text-xs text-muted-foreground' title={result.pair.sanitized_content}>
                <Markdown
                  className='font-normal max-h-20 overflow-hidden pl-2 border-l-2'
                  content={result.pair.sanitized_content}
                  ddqId={result.pair.ddq_id}
                />
              </div>
            </div>
            {/* <div className='line-clamp-2 text-xs text-muted-foreground'>
              <Markdown
                className='font-normal max-h-16 overflow-hidden pl-2 border-l-2'
                content={result.pair.answer}
                ddqId={result.pair.ddq_id}
              />
            </div> */}
          </button>
        ))}
      </div>
    </ScrollArea>
  );
}
