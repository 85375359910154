import React, { useRef } from 'react';
import { Button } from '../ui/button';

import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import { captureEvent } from '@/lib/analytics';

const HighlightCopy: React.ComponentType<
  React.PropsWithChildren<{
    copyTitle?: string;
    hiddenChildren?: boolean;
    collapse?: boolean;
  }>
> = ({
  children,
  copyTitle = 'Copy',
  hiddenChildren = false,
  collapse = false
}) => {
  const textRef = useRef(null);

  const copyText = () => {
    captureEvent('copy', {
      copyTitle
    })

    const selection = window.getSelection();
    const range = document.createRange();

    if (textRef.current === null || selection === null)
      throw Error('No text ref to select');

    range.selectNodeContents(textRef.current);
    selection.removeAllRanges();
    selection.addRange(range);
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy', err);
    }
    // Deselect text
    selection.removeAllRanges();
  };

  return (
    <>
      <div
        className={
          hiddenChildren
            ? 'flex justify-end w-full z-50 h-3 px-2 -mt-2'
            : 'flex justify-end w-full z-50 h-3 px-2 mt-1' +
              (collapse ? ' -mt-8' : '')
        }
      >
        <Button className={'p-2 h-8'} variant={'ghost'} onClick={copyText}>
          {copyTitle}
          <ClipboardCopyIcon className='ml-2' />
        </Button>
      </div>
      <div
        className={hiddenChildren ? 'absolute -left-full -top-full' : ''}
        ref={textRef}
      >
        {children}
      </div>
    </>
  );
};

export default HighlightCopy;
